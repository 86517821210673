<template>
  <div class="section">
    <div class="bg-violet"></div>
    <div class="container">
      <div id="quiz-section">
        <div class="row">
        <div class="col-lg-12 my-auto" v-for="(question,index) in questions.slice(a,b)" :key="index">
          <div class="quiz-container justify-content-center text-center">
              <div class="question">
                {{ question.content }}
              </div>
              <p class="quiz-number">
                {{ b }} / {{ questions.length }}
              </p>
              <button 
                @click="nextQuestion(question.id, question.answers[0].id, question.answers[0].content)" 
                class="answer d-flex align-items-center justify-content-center"
                :class="{ chosen: selected == question.answers[0].id }"
                :disabled="isDisabled"
              >
                {{ question.answers[0].content }}
              </button>
              <p class="mt-4">atau</p>
              <button 
                @click="nextQuestion(question.id, question.answers[1].id, question.answers[1].content)" 
                class="answer d-flex align-items-center justify-content-center"
                :class="{ chosen: selected == question.answers[1].id }"
                :disabled="isDisabled"  
              >
                {{ question.answers[1].content }}
              </button>
          </div>
          <div class="d-flex justify-content-between">
            <router-link class="btn" :to="{name: 'instruction'}">
                <fa-icon icon="arrow-left" class="mr-2"/>
                Kembali
            </router-link>
            <div @click="submitAnswers()" class="btn-violet" v-if="show_button">
              Selesai
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from "axios";

export default {
  name: "QuizSection",
  data() {
    return {
      questions: [],
      a: 0,
      b: 1,
      selected: null,
      isDisabled: false,
      show_button: false,
    }
  },
  
  methods: {
    nextQuestion(question_id, answer_id, content) {
      this.isDisabled = true;
      this.$store.state.answerData.push({ "question_id": question_id, "answer_id": answer_id, "content": content });
      this.selected = answer_id;
      setTimeout(() =>  {
        if (this.a < this.questions.length-1) {
          this.a++;
          this.b++;
          this.isDisabled = false;  
        }
        if (this.questions.length-1 == this.a) {
          this.show_button = true;
        }
      }, 500); 
    },
    submitAnswers() {
      this.$router.push({name: 'user-info'});
    }
  },
  async mounted() {
    const response = await axios.get('https://app.semesta.io/api/bb549c966f24fd99176623745abc503e/led-free-test');
    this.questions = response.data.data.sections[0].questions;
  }
};
</script>

<style scoped>

</style>
