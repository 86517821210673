import { render, staticRenderFns } from "./QuizSection.vue?vue&type=template&id=2dfdc95d&scoped=true&"
import script from "./QuizSection.vue?vue&type=script&lang=js&"
export * from "./QuizSection.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dfdc95d",
  null
  
)

export default component.exports